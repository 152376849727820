import React from 'react';
import classes from './Graves.module.css';
import img from '../../assets/images/official/grounds.jpg'
import img1 from '../../assets/images/official/grounds1.jpg'
import { HashLink as Link } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';

const graves = ( ) => {

    return (
        <div id='Graves' className={classes.Graves}>
            <ScrollAnimation animateIn="fadeIn" animateOut='fadeOut'>
            <h1>Bethany Memorial Cemetery</h1>
            <p>The Bethany Memorial Cemetery sits in beautiful Charles City Co, Virginia adjacent to
                Bethany Presbyterian Church. The grounds are managed by our association, in which
                we take great pride in its conservation. While the church is no longer operational,
                the cemetery continues to accept new requests for burial plots. To make such a request,
                please do so by clicking the button below or by contacting one of the following Burial 
                Committee members:
            </p>
            </ScrollAnimation>
            <ul>
                <li> Karen Goodloe – 804-338-9186</li>
                <li> Richard Crom – 804-937-9854</li>
                <li> Warren Haupt – 804-426-8661</li>
            </ul>

            <Link to='/#Contact'><button>Make a burial request</button></Link>

            <h2>View Gravestones Online</h2>

            <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutRight'>
                <p>
                    To view the gravestones at Bethany Presbyterian Church online, please click the button below to go to the 
                    Bethany listings on VirginiaGravestones.org. The list is partial and we are working to update it.
                </p>
            </ScrollAnimation>
            <a href='https://virginiagravestones.org/cemetery.php?cemID=2728' target='_blank' rel="noopener noreferrer"><button>View Graves</button></a>

            <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutRight'>
                <p className={classes.PadTop}>
                    Bethany has contracted with a software company to create an online map of Bethany's cemetery showing occupied, 
                    reserved and available gravesites. Click the link below to see Bethany's map. Note: You may need to click the 
                    "plus" button at the bottom right of the map several times to view the gravesite numbers. Once you see the numbers, 
                    click on a gravesite to get more information about it. Please be patient as the site loads.
                </p>
            </ScrollAnimation>
            <a href='https://map.chronicle.rip/Bethany-Memorial-Cemetery' target='_blank' rel="noopener noreferrer"><button>Bethany Cemetery Map</button></a>

            <img style={{marginTop: '50px'}} src={img} alt='The Bethany Cemetery Grounds' />
            <img src={img1}  alt='The Bethany Cemetery Grounds' />
        </div>
    )

}

export default graves;